import './App.css';
import { Routes, Route } from "react-router-dom";
import Dashbord from './Component/Dashbord';
import CareerPtahDetail from './Component/CareerPtahDetail';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { useState } from 'react';
import { GlobalContext } from './Constant/GlobalContext';
import Loader from './Component/Layout/loader';


function App() {
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState(sessionStorage.getItem('search') ? sessionStorage.getItem('search') : "");
  

  return (<>
    {isLoading &&<div className='loader'><Loader/></div>}
    <ToastContainer />
    <GlobalContext.Provider value={{
      search,
      setSearch,
      isLoading,
      setIsLoading
    }}>
    <Routes>
      <Route path='/' element={<Dashbord/>}/>
      <Route path='/detail/:jobId' element={<CareerPtahDetail/>}/>
    </Routes>
    </GlobalContext.Provider>
  </>
  );
}

export default App;
