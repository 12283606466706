import React, { useContext, useEffect, useState } from 'react';
import Header from '../Layout/header';
import { careerPathDetail } from '../../action/jobAction';
import { useNavigate, useParams } from 'react-router-dom';
import { yearsPR } from '../../utils';
import { GlobalContext } from '../../Constant/GlobalContext';

function CareerPathDetail() {
  const {setIsLoading, setSearch}: any = useContext(GlobalContext);
  const [currPathDetail, setCurrPathDetail]: any = useState();
  const [pathDetail, setPathDetail]: any = useState([]);
  const [page, setPage] = useState(1);
  const [maxYear, setMaxYear] = useState();
  const {jobId} = useParams();
  const navigate = useNavigate();

  useEffect(() => { // fetch single job detail
    ;(async() => {
      let pathArray: any = []
      let obj = {
        page: page,
        jobId: jobId
      }
      setIsLoading(true)
      const getPathDetail = await careerPathDetail(obj)
      Object.keys(getPathDetail.newCareerPathData).map((a) => {
        pathArray.push(getPathDetail.newCareerPathData[a])
        return a
      })
      let yearsArray: any = []
      let getPathData : any = [...pathDetail, ...pathArray]
      getPathData.map((b: any, i: any) => {
        if(b[b.length - 1].maxMedianYear) {
          yearsArray.push(b[b.length - 1].maxMedianYear)
        }
        return b
      })
      const getMaxYears: any = Math.max(...yearsArray)
      setMaxYear(getMaxYears)
      setPathDetail(getPathData);
      setCurrPathDetail(getPathDetail);
      setIsLoading(false)
    })(); 
  }, [page])// eslint-disable-line


  const onHandleShowMore = () => {
    setPage(currPathDetail.page + 1)
  }

  const onHandleFirstPage = () => { // redirect to home page
    setSearch()
    sessionStorage.removeItem('search')
  }

  const onHandleBack = () => {// redirect to list page
    navigate("/#");
  }

  let currData = currPathDetail && currPathDetail.totalPages !== currPathDetail.page

  return (<>
    <Header onHandleFirstPage={onHandleFirstPage}/>
    <div className='pathDetailContainer'>
    <h2 className='detailTitle'>Job Title: {currPathDetail?.jobTitle}</h2>
    <div className='detailSearchBox row'>
    <button type="button" className="btn btn-light col backBtn"
     onClick={onHandleBack}
    ><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
      <div className='col detailSearch'>
        {/* temporary comment search box */}
          {/* <div className="input-group">
            <input className="form-control searchInput border-end-0 border" type="text" 
              placeholder='Search for job map'
            />
                <button className="btn btn-primary bg-orange border-start-0 border serchBtn" 
                type="button"
                >
                  <i className="fa fa-search"></i>
                </button>
          </div> */}
      </div>
    </div>
    <div className="hierarchy-viewer chartBox">
      <div className="react-hierarchy-container">
        <div className="react-hierarchy-root-horizontal">
          {pathDetail && pathDetail.length > 0 ? (
          <div
            className="react-hierarchy-node-container react-hierarchy-node-container-horizontal"
          >
            <div style={{width: '8%'}} className="react-hierarchy-node react-hierarchy-node-horizontal">
                <div className="node-text-box textBox">
                  <strong>{currPathDetail?.jobTitle}</strong>
                </div>
                <div className="node-marker">
                  <div className="node-box ngx-hierarchy-border level1"></div>
                  <div className="node-line node-line-horizontal"></div>
                </div>
            </div>
            <div
              className="react-hierarchy-children react-hierarchy-children-horizontal"
            >
              {pathDetail && pathDetail.length > 0 &&
               pathDetail.map((list: any, idx: any) => {
                  let getPR = yearsPR(maxYear, list[list.length - 1].maxMedianYear)
                  return(
                    <div style={{width: `${getPR}%`}} key={idx + 1} className="children-container children-container-horizontal">
                    <div className="lines-container lines-container-horizontal">
                      <div className={idx === (pathDetail.length - 1) && !currData ? "lines linesCurvLast" : "lines"}></div>
                      <div className={((idx === 0 || idx === (pathDetail.length - 1))) ? "m-line m_lines": "m-line"}></div>
                      <div className={idx === 0 ? "lines linesCurvFirst" : "lines"}></div>
                    </div>
                    <div
                      className="react-hierarchy-node-container react-hierarchy-node-container-horizontal"
                    >
                      {list && list.map((path:any, index: any) => {
                        let years = path?.maxMedianYear
                        return(<>
                          {path?.title ? 
                            <div key={path?.id}
                              className="react-hierarchy-node react-hierarchy-node-horizontal"
                            >
                              <div className="node-text-box">
                                <strong className='strong'>
                                  {(list.length - 2) === index ? <span className='lastChild'>{path?.title}</span>
                                  :<>{path?.title}</>}
                                </strong>
                              </div>
                              <div className="node-marker">
                                <div className="node-line node-line-horizontal"></div>
                                <div className="node-box ngx-hierarchy-border level1"></div>
                              </div>
                            </div>
                          :
                          <div key={index}
                              className="react-hierarchy-node react-hierarchy-node-horizontal-year"
                            >
                              <span className='yearTextBox'>{`${years} years`}</span>
                            </div>
                          }
                          </>)
                      })}
                    </div>
                  </div>
                  )
                })
              }
              {currPathDetail && currPathDetail.totalPages !== currPathDetail.page && pathDetail && pathDetail.length > 0 ?
              <div style={{width:"10%"}} key={0} className="children-container children-container-horizontal">
                <div className="lines-container lines-container-horizontal">
                  <div className="lines"></div>
                  <div className="m-line"></div>
                  <div className="lines"></div>
                </div>
                <div
                  className="react-hierarchy-node-container react-hierarchy-node-container-horizontal"
                >
                  <div
                    className="react-hierarchy-node react-hierarchy-node-horizontal"
                  >
                    <div className="node-marker cursor" onClick={onHandleShowMore}>
                      <div className="node-box ngx-hierarchy-border level1"></div>
                      <span className='showmore'>Show More</span>
                    </div>
                  </div>
                </div>
              </div>
              :null}
            </div>
          </div>) : <div className="noDataFound" >There are no records to display</div>}
        </div>
      </div>
    </div>
    </div>
    </>);
}

export default CareerPathDetail;