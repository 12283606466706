import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { useMemo } from 'react';

function CareerPathList({jobListData, handlePageChange, initialVal,isLoading, onHandleShowPerPage}: any) {
  const navigate = useNavigate();
  const onHandleDetail = (id: any) => { // redirect to detail page
    navigate(`/detail/${id.id}`)
  }
 

  const columns = useMemo(() => [
    {
      name: "Job Positions",
      selector: (row: any) => row.title,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row: any) => <button onClick={() => onHandleDetail(row)} className='eyeButton'><i className="fa fa-eye" aria-hidden="true"></i></button>,
    //   sortable: false
    // },
  ],
  [])// eslint-disable-line


  return (<>
    {/* <div>
      <h2 className='tableTitle'>Career Path List</h2>
    </div> */}
    <div className="tableList">
        <DataTable
          pagination
          paginationServer
          columns={columns}
          data={jobListData?.jobs}
          paginationTotalRows={jobListData?.totalItems}
          paginationDefaultPage={initialVal.page}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          paginationPerPage={initialVal?.size}
          onChangeRowsPerPage={(newPerPage, page) => onHandleShowPerPage(newPerPage, page)}
          onChangePage={(page) => handlePageChange(page)}
          onRowClicked={onHandleDetail}
          highlightOnHover
        />
    </div>
  </>);
}

export default CareerPathList;