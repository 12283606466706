import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../Constant/GlobalContext';
import maplogo from "../../assets/logo.svg";

let styles: any = {
  backgroundColor: "#f3f3f3",
  position: "fixed",
  width: "100%",
  zindex: 99
}

const Header = ({onHandleSearch, onHandleFirstPage, onHandleKeyPress}: any) => {
  const {search, setSearch} : any = useContext(GlobalContext);
  const {jobId} = useParams();

  return (
    <header>
      <nav style={styles} className="navbar navbar-expand-lg navbar-dark fixed-top shadow-sm">
        <div className="container-fluid">
              {/* eslint-disable-next-line */}
              <a href='#' className="headerLogo navbar-brand text-black" onClick={onHandleFirstPage}>
              <img src={maplogo} alt='header-logo' className="header-img"/> Career Map
              </a>
              {!jobId ? (<>
              <button className="navbar-toggler navSearchBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                {/* <span className="navbar-toggler-icon"></span> */}
                <i className="fa fa-search navSearchIcon" aria-hidden="true"></i>
              </button>
              <div className="collapse navbar-collapse searchInputHeader" id="navbarSupportedContent">
                <div className="input-group">
                  <input className="form-control searchInput border-end-0 border" type="text" 
                    placeholder='Search for job title'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    onKeyDown={onHandleKeyPress}
                  />
                  <button className="btn btn-primary bg-red border-start-0 border serchBtn" 
                  type="button" onClick={onHandleSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              </>): null}
        </div>
      </nav>
    </header>
  );
}

export default Header;