import React, {useContext, useEffect, useState } from 'react';
import Header from '../Layout/header';
import CareerPathList from './careerPathList.tsx';
import { careerPathList } from '../../action/jobAction';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../Constant/GlobalContext';
import maplogo from "../../assets/logo.svg";
// import ".bootstrap/less/bootstrap.less";

const Dashbord = () => {
  const [jobListData, setJobListData]: any = useState([])
  const {search, setSearch, isLoading, setIsLoading}: any = useContext(GlobalContext)
  const [initialVal, setInitialVal] = useState({
    page: 1,
    size: 10,
    search: search
  })

  const onHandleSearch = () => {
    if(search) {
      setInitialVal({...initialVal, search: search})
      sessionStorage.setItem('search', search);
      getListData(1, initialVal.size);
    } else {
      toast.error("Please enter serch text")
    }
  }
  const handlePageChange = (page: any) => {
      setInitialVal({...initialVal, page: page})
      getListData(page, initialVal.size)
  }

  const onHandleShowPerPage = (newPerPage: any, page: any) => {// set limit per page
    if(!jobListData?.jobs?.length) return;
      setInitialVal({...initialVal, size: Number(newPerPage), page: Number(page)})
      getListData(page, newPerPage);
  }

  const onHandleFirstPage = () => {// redirect to home page
    setSearch()
    sessionStorage.removeItem('search')
  }

  const onHandleKeyPress = (event: any) => {
    if(event.key === "Enter") {
      onHandleSearch()
    }
  }

  useEffect(() => { // fetch and set job list data
    if(sessionStorage.getItem('search')) {
      getListData(initialVal.page, initialVal.size)
    }
  }, []) // eslint-disable-line

  const getListData = async (page: any, perPage: any) => {
    let searchItem: any = sessionStorage.getItem('search')
    if(searchItem !== null &&  searchItem !== undefined) {
      let obj = {
        page: page - 1,
        size: perPage,
        search: search
      }
      setIsLoading(true)
      let jobsList = await careerPathList(obj)
      setJobListData(jobsList);
      setIsLoading(false)
    }
  }

  return (<>
    <div className={sessionStorage.getItem('search') ? "mainContainerList":"mainContainer"}>
      {sessionStorage.getItem('search') ? <>
        <Header
        onHandleSearch={onHandleSearch}
        onHandleFirstPage={onHandleFirstPage}
        onHandleKeyPress={onHandleKeyPress}
        />
        <div className='listContainer'>
          <CareerPathList
          jobListData={jobListData}
          handlePageChange={handlePageChange}
          initialVal={initialVal}
          onHandleShowPerPage={onHandleShowPerPage}
          isLoading={isLoading}
          />
        </div>
      </>  :(
        <div className='serchContainer'>
          <h1 className='serchLable'> <img src={maplogo} alt="logo" className="image_logo"/> Career Map</h1>
          <div className="input-group">
            <input className="form-control searchInput border-end-0 border" type="text" 
              placeholder='Search for job title'
              value={search}
              onKeyDown={onHandleKeyPress}
              onChange={(e) => {
                if(!e.target.value) {
                  sessionStorage.setItem('search', "")
                }
                setSearch(e.target.value)
              }}
            />
                <button className="btn btn-primary bg-orange border-start-0 border serchBtn" 
                type="button" onClick={onHandleSearch}
                >
                  <i className="fa fa-search"></i>
                </button>
          </div>
        </div>
      )}
    </div>
  </>);
}

export default Dashbord;