import axios from "axios";
import { apiUrl } from "./apiUrl";

const BASE_URL = 'https://sandbox-career-map-mw.dotin.us/'

export const careerPathList = async (data: any) => { // fetch job list function
    let URL: string= '';
    if(data) {
       URL = `${BASE_URL}${apiUrl.careerPathList}?page=${data.page}&size=${data.size}&search=${data.search}`
    } else {
       URL = `${BASE_URL}${apiUrl.careerPathList}`
    }
    
    let response: any =  await axios.get(URL, {
        headers: {'Content-Type': 'application/json'}
    }).then((listData) => {
        return listData
    }).catch((error) => {
        console.log("error list =", error)
    })
    return response?.data?.data
    
}

export const careerPathDetail = async (data: any) => { // fetch job path detail by id
    let response: any =  await axios.get(`${BASE_URL}${apiUrl.careerPathDetail}?page=${data?.page}&jobId=${data?.jobId}`,
    {
        headers: {'Content-Type': 'application/json'},
    }
    )
    .then((listData) => {
        return listData
    }).catch((error) => {
        console.log("error list =", error)
    })
    return response?.data?.data
    
}
